import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import LogoImg from "../asset/Logo.png";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../style/Navbar.css";

import i18n from "../i18n.jsx"
import { useTranslation } from "react-i18next";


const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let menuRef = useRef();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleDropdownClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!menuRef.current) return;

    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <nav className="nav">
      <div className="nav_start">
        <Link to="/" className="nav_link">
          <img alt="logo" src={LogoImg} height="80" className="navbar_logo" />
        </Link>
      </div>

      {/* Menu hamburger per dispositivi mobili */}
      <button className="hamburger_menu" onClick={toggleMobileMenu}>
        <i className="pi pi-bars"></i>
      </button>

      {isMobileMenuOpen && (
        <div className="mobile_menu_overlay">
          <button className="close_menu_button" onClick={toggleMobileMenu}>
            <i className="pi pi-times"></i>
          </button>

          <div className="mobile_menu_content">
            <img alt="logo" src={LogoImg} height="80" className="mobile_logo" />
            <ul className="mobile_nav_menu">
              <li className="mobile_nav_item">

                <Link to="/" className="nav_link" onClick={toggleMobileMenu}>
                  <i className="pi pi-home"></i> Home
                </Link>
              </li>
              <li className="mobile_nav_item">
                <Link
                  to="/about"
                  className="nav_link"
                  onClick={toggleMobileMenu}
                >
                  <i className="pi pi-briefcase"></i> {t('navigation.company')}
                </Link>
              </li>
              <li className="mobile_nav_item">
                <Link
                  to="/supporto"
                  className="nav_link"
                  onClick={toggleMobileMenu}
                >
                  <i className="pi pi-question-circle"></i> {t('navigation.support')}
                </Link>
              </li>

              <div ref={menuRef} className="dropdown-container">
                <div
                  className="dropdown-toggle"
                  id="dropdown-basic-button"
                  title="Demo"
                  onClick={handleDropdownClick}
                >
                  <i className="pi pi-play-circle"></i> Demo
                  {open && (
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <a href="http://cityticket.semete.it" target="_blank" rel="noopener noreferrer">City Ticket</a>
                      </li>
                      <li className="dropdown-item">
                        <a href="http://classbooking.semete.it" target="_blank" rel="noopener noreferrer">Prenotazione Scuole</a>
                      </li>
                      <li className="dropdown-item">
                        <a href="https://demo.semete.it/cantieri/" target="_blank" rel="noopener noreferrer">Cantieri</a>
                      </li>
                    </ul>
                  )}
                </div>
              </div>


            </ul>
            <div className="language_switcher">
              <button className="btn-translate-primary" onClick={() => changeLanguage('it')}>
                IT
              </button>
              <button className="btn-translate-secondary" onClick={() => changeLanguage('en')}>
                EN
              </button>
            </div>
          </div>
        </div>
      )
      }

      <ul className="nav_menu">
        <li className="nav_item">
          <Link to="/" className="nav_link">
            <i className="pi pi-home"></i> Home
          </Link>
        </li>

        <li className="nav_item">
          <Link to="/about" className="nav_link">
            <i className="pi pi-briefcase"></i> {t('navigation.company')}
          </Link>
        </li>

        <li className="nav_item">
          <Link to="/supporto" className="nav_link">
            <i className="pi pi-question-circle"></i> {t('navigation.support')}
          </Link>
        </li>

        <div ref={menuRef} className="dropdown-container">
          <div
            className="dropdown-toggle"
            id="dropdown-basic-button"
            title="Demo"
            onClick={handleDropdownClick}
          >
            <i className="pi pi-play-circle"></i> Demo
            {open && (
              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <a href="http://cityticket.semete.it" target="_blank" rel="noopener noreferrer">City Ticket</a>
                </li>
                <li className="dropdown-item">
                  <a href="http://classbooking.semete.it" target="_blank" rel="noopener noreferrer">Prenotazione Scuole</a>
                </li>
                <li className="dropdown-item">
                  <a href="https://demo.semete.it/cantieri/" target="_blank" rel="noopener noreferrer">Cantieri</a>
                </li>
              </ul>
            )}
          </div>
        </div>

      </ul>
      <div className="language_switcher nav_btn">
        <button className="btn-translate-primary" onClick={() => changeLanguage('it')}>
          IT
        </button>
        <button className="btn-translate-secondary" onClick={() => changeLanguage('en')}>
          EN
        </button>
      </div>
    </nav >
  );
};

export default Navbar;